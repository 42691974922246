<template lang="pug">
.page
  el-card.tableCard
    template(#header)
      span 文章分类
      el-button(style="float:right" icon="el-icon-plus" type="success" @click="handleAdd") 
    el-table(:data="list")
      el-table-column(prop="_id" label="id")
      el-table-column(prop="name" label="名称")
      el-table-column(prop="desc" label="备注")
      el-table-column(fixed="right" label="操作" width="120")
        template(#default="scope")
          el-button(type="primary" icon="el-icon-edit" size="mini" plain @click="handleEdit(scope.row)")
          el-popconfirm(title="确定要删除吗？" @confirm="handleDelete(scope.row)")
            template(#reference)
              el-button(type="danger" icon="el-icon-delete" size="mini" plain)

  el-dialog(v-model="isShowEditForm")
    el-form(label-width="6em")
      el-form-item(label="id" v-if="editFormData._id")
        el-input(v-model="editFormData._id" disabled)
      el-form-item(label="名称")
        el-input(v-model="editFormData.name")
      el-form-item(label="备注")
        el-input(v-model="editFormData.desc")
      el-form-item
        el-button(type="primary" @click="handleSubmitEdit") 保存
      
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'

const { fetch, message } = inject('global')

const loading = ref(false)
const list = ref([])

function getList() {
  fetch.get('/articleCategory?size=-1').then(res => {
    list.value = res.list
  })
}

const isShowEditForm = ref(false)
const editFormData = ref({
  _id: '',
  name: '',
  desc: ''
})

function handleAdd() {
  editFormData.value = {
    _id: '',
    name: '',
    desc: ''
  }
  isShowEditForm.value = true
}
function handleEdit(val) {
  editFormData.value = { ...val }
  isShowEditForm.value = true
}
function handleDelete(val) {
  fetch.delete(`/articleCategory/${val._id}`).then(() => {
    message.success('删除成功')
    getList()
  })
}

function handleSubmitEdit() {
  const { _id, ...payload } = editFormData.value
  return Promise.resolve()
    .then(() => {
      if (_id) {
        return fetch.put(`/articleCategory/${_id}`, {
          ...payload
        })
      } else {
        return fetch.post(`/articleCategory`, {
          ...payload
        })
      }
    })
    .then(() => {
      message.success('保存成功')
      isShowEditForm.value = false
      getList()
    })
}

onMounted(() => {
  getList()
})
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
</style>
